import styled, { css } from 'styled-components';

import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';
import { Root as LabelPrimitiveRoot } from '@radix-ui/react-label';

import { BaseLoading } from '../../../../components/BaseLoading';
import { BaseRadioGroup } from '../../../../components/BaseRadio';
import { CheckBoxGroup } from '../../../../components/CheckBoxGroup';
import { ContentPagination } from '../../../../components/ContentPagination';
import { TemplateCard } from '../TemplateCard';

export const Label = styled(LabelPrimitiveRoot)`
  background-color: ${colors.white300};
  color: ${colors.labelText};
  font-size: 0.875rem;
  left: 16px;
  max-width: 90%;
  overflow: hidden;
  padding: 1px 6px;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
    padding 0.15s ease-in-out;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
`;

interface IFieldsetProps {
  readonly filled: boolean;
  readonly isInvalid?: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorder};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus};

    & ${Label} {
      color: ${colors.fieldFocus};
      top: -2px;
      transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
      white-space: normal;
    }
  }

  ${({ filled = false, isInvalid = false }) =>
    filled &&
    (!isInvalid
      ? css`
          border: 1px solid ${colors.fieldFocus};

          & ${Label} {
            color: ${colors.fieldFocus};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `
      : css`
          border: 1px solid ${colors.red300};

          & ${Label} {
            color: ${colors.red300};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `)}
`;

export const Input = styled.input`
  -webkit-appearance: none;
  background-color: none;
  border-radius: ${radii.px};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
`;

export const RadioGroup = styled(BaseRadioGroup)`
  margin-bottom: 0.75rem;
`;

export const RadioContainer = styled.div`
  align-items: center;
  color: ${colors.text};
  display: flex;
`;

export const RadioLabel = styled(LabelPrimitiveRoot)`
  font-size: 1rem;
  flex-grow: 1;
  padding: 0 0.375rem;
  font-weight: 400;
`;

export const SelectTemplateTypeContainer = styled.div`
  margin-bottom: 1rem;
`;

export const SelectContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-basis: 50vh;
  flex-direction: column;
  overflow-y: auto;

  ${scrollbar}
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const TemplateCardCustom = styled(TemplateCard)``;

export const CustomCheckBoxGroup = styled(CheckBoxGroup)`
  color: ${colors.blue300};
  & ${TemplateCardCustom} + ${TemplateCardCustom} {
    margin-top: 0.125rem;
  }
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const TitleText = styled.p``;

export const Content = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  flex-grow: 1;
`;

export const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textLight};
  text-align: center;
  margin-top: 1rem;
`;

export const CardContainer = styled.div`
  & > a + a {
    margin-top: 0.5rem;
  }
`;

export const ContentPaginationCustom = styled(ContentPagination)`
  overflow: unset;
  height: 100%;
`;
