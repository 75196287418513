import { MdCheck } from 'react-icons/md';
import styled from 'styled-components';

import { BaseCheckbox } from '@gbs-monorepo-packages/common';
import { colors, scrollbar } from '@gbs-monorepo-packages/styles';

import { CheckBoxGroup } from '../../../../components/CheckBoxGroup';

export const CustomCheckBoxGroup = styled(CheckBoxGroup)`
  color: ${colors.blue300};
`;

export const SelectContainer = styled.div`
  height: 450px;
  overflow: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  margin-bottom: 1rem;
  margin-top: 0.5rem;

  ${scrollbar};
`;

export const CustomCheckbox = styled(BaseCheckbox)`
  background-color: ${colors.white300};
  border: 1px solid ${colors.blue300};
  &[data-state='checked'] {
    background-color: ${colors.blue300};
  }

  &[data-disabled] {
    background-color: ${colors.backgroundHover};
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Flex = styled.div`
  display: flex;
  position: stick;
  gap: 0.5rem;
  padding: 0.5rem 0.585rem;
`;

export const Title = styled.p`
  font-size: 1rem;
  line-height: 1.1;
  flex-grow: 1;
  color: ${colors.text};
`;

export const TitleSelectAll = styled(Title)`
  font-weight: bold;
`;

export const CheckIcon = styled(MdCheck)`
  height: 100%;
  width: 100%;
`;

export const ModalText = styled.p`
  padding-bottom: 1rem;
`;
