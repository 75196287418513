import { MdAdd, MdOutlineLayersClear } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import { Item } from '@radix-ui/react-dropdown-menu';

import { BaseLoading } from '../../../../components/BaseLoading';

export const PagesSection = styled.section`
  align-items: center;
  background-color: ${colors.gray100};
  border-radius: ${radii.sm};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0.75rem 0.375rem;
  padding: 0.5rem 0.75rem;
  width: 15%;
`;

export const PagesSectionHeader = styled.header`
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.125rem;
  width: 100%;
`;

export const PagesSectionHeaderText = styled.h3`
  color: ${colors.textLight};
  font-size: 1.125rem;
  font-weight: 400;
`;

export const PagesSectionHeaderTopContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 0.75rem;
`;

export const PagesSectionHeaderBottomContainer = styled.div`
  width: 100%;
`;

export const ButtonActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
`;

export const ButtonAction = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  justify-content: center;
  margin-left: 0.25rem;
  padding: 0.375rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const AddIcon = styled(MdAdd)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const MultipleDeleteIcon = styled(MdOutlineLayersClear)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const CardContainer = styled.div`
  & > a + a {
    margin-top: 0.5rem;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-basis: 50vh;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${colors.scrollbar};
    border: 2px solid transparent;
    // FIXME: replace radius by constants

    border-radius: 10px;
    &:hover {
      background-color: ${colors.scrollbarHover};
    }
  }
  &:not(:hover) {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
  }
`;

export const LoadingCoursePagesCardsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 12.5rem;
`;

export const LoadingCoursePagesCards = styled(BaseLoading)`
  height: 2rem;
  width: 2rem;
`;

interface ICardButtonProps {
  selected?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
}

export const CardButton = styled.a<ICardButtonProps>`
  align-items: center;
  background-color: ${colors.blue200};
  border-radius: ${radii.xs};
  border: none;
  color: ${colors.white300};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 4rem;
  opacity: unset;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  scroll-snap-align: start;
  text-align: start;
  text-decoration: none;

  &:hover {
    filter: brightness(0.95);
  }

  ${({ selected = false }) =>
    selected &&
    css`
      background-color: ${colors.button};
      color: ${colors.white300};
    `}

  ${({ highlighted = false }) =>
    highlighted &&
    css`
      background-color: ${colors.orange400};
      color: ${colors.white300};
    `}

    ${({ selected = false, highlighted = false }) =>
    selected &&
    highlighted &&
    css`
      background-color: ${colors.orange600};
      color: ${colors.white300};
    `}

  ${({ disabled = false }) =>
    disabled &&
    css`
      background-color: ${colors.blue50};
      color: ${colors.blue400};
    `}
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 8vw;
  white-space: nowrap;
`;

export const CardName = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardType = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
  margin-top: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardExtraContent = styled.div`
  display: flex;
`;

export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  margin-left: 0.5rem;
  width: 2.75rem;

  & > button:hover {
    background-color: ${colors.backgroundHoverLight};
  }
`;

export const DropdownItem = styled(Item)`
  border-radius: ${radii.sm};
  color: ${colors.labelText};
  font-size: 1rem;
  font-weight: 400;
  height: 1.5rem;
  line-height: 1.125rem;
  padding-left: 25;
  padding: 5px;
  position: relative;
  user-select: none;

  &[data-highlighted] {
    background-color: ${colors.backgroundHover};
    outline: none;
  }
`;

export const FeedbackCard = styled(CardButton)`
  background-color: ${colors.white300};
  color: ${colors.textLight};
  margin-top: 0.5rem;
`;

export const CheckContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  width: 2.75rem;
`;

export const PagesSectionPagesFoundText = styled.p`
  color: ${colors.textLight};
  font-size: 0.95rem;
  font-weight: 400;
  margin-top: 0.5rem;
`;
