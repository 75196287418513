import { ChromePicker } from 'react-color';
import { MdColorLens } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';
import { Root as LabelPrimitiveRoot } from '@radix-ui/react-label';

import { BaseLoading } from '../../../../components/BaseLoading';
import { BaseSlider } from '../../../../components/BaseSlider';
import { SelectData } from '../../../../components/SelectData';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 75vh;
  padding-top: 0.25rem;
  margin-bottom: 0.25rem;

  ${scrollbar};
`;

export const SelectDataCustom = styled(SelectData)`
  border: none;
  border-radius: ${radii.px};
`;

export const Label = styled(LabelPrimitiveRoot)`
  background-color: ${colors.white300};
  color: ${colors.labelText};
  font-size: 0.875rem;
  left: 16px;
  max-width: 90%;
  overflow: hidden;
  padding: 1px 6px;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
    padding 0.15s ease-in-out;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
`;

export const Input = styled.input`
  background: none;
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
`;

interface IFieldsetProps {
  readonly filled: boolean;
  readonly isInvalid?: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  flex: 1;
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorder} !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
  flex-grow: 1;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus} !important;

    & ${Label} {
      color: ${colors.fieldFocus};
      top: -2px;
      transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
      white-space: normal;
    }
  }

  ${({ filled = false, isInvalid = false }) =>
    filled &&
    (!isInvalid
      ? css`
          border: 1px solid var(--gbs-color-blue);

          & ${Label} {
            color: var(--gbs-color-blue);
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `
      : css`
          border: 1px solid ${colors.red300};

          & ${Label} {
            color: ${colors.red300};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `)}

  & ${Input} ~ * ${SelectDataCustom} {
    border-left: 1px solid ${colors.divider};
  }
`;

export const SliderCustom = styled(BaseSlider)`
  flex-grow: 5;
  padding-right: 1rem;
`;

export const GlobalTemplateCheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.25rem;
`;

export const GlobalTemplateCheckboxLabel = styled.label`
  margin: 0 0.5rem;
`;

export const ColorPickerContainer = styled.div`
  align-items: center;
  display: flex;
  max-width: 100%;
  padding: 0;
`;

export const ColorPreview = styled.div`
  text-align: center;
  display: block;
  height: 3.25rem;
  width: 3.25rem;
  padding: 0;
  margin: 0;
  border: 1px solid ${colors.fieldFocus};
  border-top-left-radius: ${radii.px};
  border-bottom-left-radius: ${radii.px};
`;

export const ErrorMessageContainer = styled.div`
  font-size: 0.875rem;
  color: ${colors.red300};
  margin: -5px 0px 15px 0px;
`;

export const ErrorMessage = styled.p`
  margin-top: 5px;
`;

export const FieldsetCustom = styled.fieldset<IFieldsetProps>`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.lg};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
  margin: 0 1rem 0.75rem;

  &:focus-within {
    border: 1px solid var(--gbs-color-blue) !important;

    & ${Label} {
      color: var(--gbs-color-blue);
      top: -2px;
      transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
      white-space: normal;
    }
  }

  ${({ filled = false, isInvalid = false }) =>
    filled &&
    (!isInvalid
      ? css`
          border: 1px solid var(--gbs-color-blue);

          & ${Label} {
            color: var(--gbs-color-blue);
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `
      : css`
          border: 1px solid ${colors.red300};

          & ${Label} {
            color: ${colors.red300};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `)}
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
  margin-top: 1.5rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const CustomChromePicker = styled(ChromePicker)`
  width: 60% !important;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 100%;
  transform: translateX(10px);
`;

export const ColorPickerIcon = styled(MdColorLens)`
  color: ${colors.button};
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  margin-right: 1rem;
`;

interface ITemplateColorSectionProps {
  readonly hasColors?: boolean | string;
}

export const TemplateImagesColorsSection = styled.div<ITemplateColorSectionProps>`
  border-top: 1px solid ${colors.gray300};
  margin-top: 0.75rem;

  ${({ hasColors = true }) =>
    hasColors === false
      ? css`
          position: relative;
        `
      : ''};
`;

export const TemplateImagesColorsTitle = styled.p`
  padding: 0.75rem 0 1.5rem;
  font-weight: bold;
`;

export const OverlayDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverlayText = styled.p`
  font-size: 18px;
  text-align: center;
`;
