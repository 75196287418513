import { useCallback, useEffect, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';

import { type IBaseModalProps } from '../../../../components/BaseModal';
import { Card } from '../../../../components/Card';
import { CenteredText } from '../../../../components/CenteredText';
import { FilterPagination } from '../../../../components/FilterPagination';
import { FormPaginationModal } from '../../../../components/FormPaginationModal';
import { SelectData } from '../../../../components/SelectData';
import {
  TemplatesType,
  groupsTypes,
  templatesTypes,
} from '../../../../constants/TemplatesTypes';
import { useDragDrop } from '../../../../hooks/useDragDrop';
import {
  type IApiThrowsError,
  type IPaginationMetaProps,
} from '../../../../services/api';
import {
  type ICreateTemplateGroupProps,
  type IPaginationTemplatesDTO,
  type ITemplateGroupDTO,
  type ITemplatePageDTO,
  type IUpdateTemplateGroupProps,
  getTemplateGroupPages,
  getTemplatePagesByCompanyId,
} from '../../../../services/templates';
import Logger from '../../../../utils/logger';
import { ModalInfoGroupTemplate } from '../ModalInfoGroupTemplate';
import {
  CardContainer,
  Content,
  ContentPaginationCustom,
  CustomCheckBoxGroup,
  Loading,
  LoadingContainer,
  MainContainer,
  SelectContainer,
  SelectTemplateTypeContainer,
  TemplateCardCustom,
  TitleText,
} from './styles';

interface IAddPageModalProps
  extends Partial<Omit<IBaseModalProps, 'children' | 'onOpenChange'>> {
  companyId: number;
  onInsert: (templateGroup: ICreateTemplateGroupProps) => Promise<void>;
  onUpdate: (templateGroup: IUpdateTemplateGroupProps) => Promise<void>;
  onDecline: () => void;
  loading?: boolean;
  isUserAdmin?: boolean;
  isUserManager?: boolean;
  templateGroupUpdate: ITemplateGroupDTO | null;
}
interface IDroppableProps {
  droppableId: string;
  from: number;
  to: number;
}
export interface ITemplatePage extends ITemplatePageDTO {
  index?: number;
  selected?: boolean;
  order?: number;
}

export interface ITemplatePageWithOrder extends ITemplatePageDTO {
  index?: number;
  selected?: boolean;
  order: number;
}

const templatesOptionsFiltered = templatesTypes.filter(
  (i) => i.key !== TemplatesType.BUILD_SCRATCH
);

const templateOptionsStaff = templatesTypes.filter(
  (i) => i.key === TemplatesType.MY_TEMPLATES
);

const templateOptionsManager = templatesTypes.filter(
  (i) =>
    i.key === TemplatesType.MY_TEMPLATES ||
    i.key === TemplatesType.SHARE_TEMPLATES
);

const groupOptionsFiltered = groupsTypes.filter(
  (i) => i.key !== TemplatesType.BUILD_SCRATCH
);

const groupOptionsStaff = groupsTypes.filter(
  (i) => i.key === TemplatesType.MY_TEMPLATES
);

const groupOptionsManager = groupsTypes.filter(
  (i) =>
    i.key === TemplatesType.MY_TEMPLATES ||
    i.key === TemplatesType.SHARE_TEMPLATES
);

const fromGlobalTemplates = TemplatesType.GLOBAL_TEMPLATES;

const steps = [
  {
    id: 'CREATE',
    title: 'New Course Template',
  },
  {
    id: 'ADD',
    title: 'Select Templates',
  },
];

const DroppableId = 'template-course-droppable';

export const CreateNewGroupModal = ({
  open = false,
  companyId,
  onInsert,
  onUpdate,
  onDecline,
  loading,
  isUserAdmin = true,
  isUserManager = true,
  templateGroupUpdate,
  ...props
}: IAddPageModalProps): JSX.Element => {
  const isSelectOpen = useRef(new Set());
  const [loadingCreatingCourse, setLoadingCreatingCourse] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState<
    ITemplatePageWithOrder[]
  >([]);
  const [templates, setTemplates] = useState<Map<number, ITemplatePage>>(
    new Map()
  );

  const [search, setSearch] = useState<string | null>(null);
  const [templateOptionsId, setTemplateOptionsId] = useState<number>(
    templateGroupUpdate?.templateOptions?.id ?? fromGlobalTemplates
  );
  const loadedTemplatesOnce = useRef(false);
  const refFormModalContent = useRef<HTMLDivElement>(null);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);

  const noItemsSelected = !Object.keys(selectedTemplates).length;
  const sortedSelectedTemplates = Object.values(selectedTemplates).sort(
    (a, b) => a.order - b.order
  );

  const disabledButtonNext = () => {
    if (steps[currentStep].id === 'CREATE') {
      return !groupTemplateTitle || groupTemplateTitle.trim() === '';
    } else if (steps[currentStep].id === 'ADD') {
      return noItemsSelected;
    }
    return false;
  };

  // steps
  const [currentStep, setCurrentStep] = useState(0);
  const [groupTemplateTitle, setGroupTemplateTitle] = useState(
    templateGroupUpdate?.title ?? ''
  );
  const [templateOptions, setTemplateOptions] = useState<number>(
    templateGroupUpdate?.templateOptions?.id ?? TemplatesType.MY_TEMPLATES
  );

  const searchTemplates = (searchByButton = false) => {
    if (!loadingTemplates) {
      setLoadingTemplates(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);

      getTemplatePagesByCompanyId({
        clientId: companyId,
        templateOptionsId,
        page: pageAux + 1,
        limit: 10,
        filter: search ?? '',
      })
        .then((response: IPaginationTemplatesDTO) => {
          setPaginationMeta(response.meta);

          const templatesAux: Map<number, ITemplatePage> = !searchByButton
            ? new Map(templates)
            : new Map<number, ITemplatePage>();

          response.data.forEach((item) => {
            const isSelected = checkSelectedTemplate(item.id);

            const template = templatesAux.get(item.id);
            if (template) {
              templatesAux.set(item.id, {
                ...template,
                selected: isSelected,
              });
            } else {
              templatesAux.set(item.id, {
                ...item,
                selected: isSelected,
              });
            }
          });
          setTemplates(templatesAux);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingTemplates(false);
        });
    }
  };
  useEffect(() => {
    const syncForm = ({ title, templateOptions }: ITemplateGroupDTO) => {
      setGroupTemplateTitle(title ?? '');
      setTemplateOptions(templateOptions.id);
    };

    if (templateGroupUpdate) {
      syncForm(templateGroupUpdate);
    }
  }, [templateGroupUpdate]);

  useEffect(() => {
    const syncForm = async ({ id }: ITemplateGroupDTO) => {
      const result = await getTemplateGroupPages({
        templateGroupId: id,
      });

      const resultIfIndex: ITemplatePageWithOrder[] = result.data.map(
        (item) => ({
          ...item,
          index: item.id,
          selected: true,
          order:
            item.courseTemplatePageOrders?.find(
              (order) => order.courseTemplate.id === id
            )?.pageOrder ?? 0,
          templateOptionsId:
            typeof item?.templateOptions === 'number'
              ? item?.templateOptions
              : item?.templateOptions?.id,
        })
      );

      const templatesAux = new Map<number, ITemplatePage>(templates);
      resultIfIndex.forEach((item: ITemplatePageWithOrder) => {
        checkTemplate(item);
        const isSelected = true;

        const template = templatesAux.get(item.id);
        if (template) {
          templatesAux.set(item.id, {
            ...template,
            selected: isSelected,
          });
        } else {
          templatesAux.set(item.id, {
            ...item,
            selected: isSelected,
          });
        }
      });

      setTemplates(templatesAux);
    };

    if (templateGroupUpdate) {
      void syncForm(templateGroupUpdate);
    }
  }, [templateGroupUpdate]);

  const handleNextStep = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const checkSelectedTemplate = (id: number) => {
    const templatesArray = Object.values(selectedTemplates);
    const template = templatesArray.find((item) => item.id === id);
    return template !== undefined;
  };

  const handleDeclineAddPage = useCallback(() => {
    if (!isSelectOpen.current.size) {
      setGroupTemplateTitle('');
      setSearch(null);
      setSelectedTemplates([]);
      setTemplates(new Map());
      setLoadingCreatingCourse(false);
      if (!isSelectOpen.current.size) {
        setTemplateOptions(0);
        setCurrentStep(0);
      }
      onDecline?.();
    }
  }, [onDecline]);

  const checkTemplate = (data: ITemplatePageWithOrder) => {
    setSelectedTemplates((prevSelectedTemplates) => {
      const currentTemplatesCount = Object.keys(prevSelectedTemplates).length;
      const newOrder = currentTemplatesCount + 1;

      return {
        ...prevSelectedTemplates,
        [data.id]: {
          ...data,
          order: data.order ?? newOrder,
        },
      };
    });

    setTemplates((prev) => {
      const updatedMap = new Map<number, ITemplatePage>(prev);
      if (updatedMap.has(data.id)) {
        const template = updatedMap.get(data.id);
        if (template) {
          updatedMap.set(data.id, { ...template, selected: true });
        }
      }
      return updatedMap;
    });
  };

  const removeTemplate = useCallback((data: ITemplatePageWithOrder) => {
    setSelectedTemplates((prevSelectedTemplates) => {
      const { [data.id]: _, ...updatedTemplates } = prevSelectedTemplates;

      const templatesArray = Object.values(
        updatedTemplates
      ) as ITemplatePageWithOrder[];

      const sortedTemplatesArray = templatesArray.sort(
        (a, b) => a.order - b.order
      );

      sortedTemplatesArray.forEach((template, index) => {
        template.order = index + 1;
      });

      const updatedSelectedTemplates: ITemplatePageWithOrder[] =
        sortedTemplatesArray.reduce<ITemplatePageWithOrder[]>(
          (acc, template) => {
            acc[template.id] = template;
            return acc;
          },
          []
        );

      return updatedSelectedTemplates;
    });

    setTemplates((prev) => {
      const updatedMap = new Map(prev);
      if (updatedMap.has(data.id)) {
        const template = updatedMap.get(data.id);
        if (template) {
          updatedMap.set(data.id, { ...template, selected: false });
        }
      }

      updatedMap.forEach((item) => {
        if (
          item.templateOptionsId !== undefined &&
          item.templateOptionsId !== templateOptionsId &&
          data.id === item.id
        ) {
          updatedMap.delete(item.id);
        }
      });

      return updatedMap;
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (search !== null) {
        searchTemplates(true);
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  useEffect(() => {
    setTemplateOptionsId(templateOptionsId);
  }, [templateOptionsId]);

  useEffect(() => {
    loadedTemplatesOnce.current = false;
    setSearch(null);
  }, [companyId, templateOptionsId]);

  useEffect(() => {
    let mount = true;

    const loadTemplatesOnce = async () => {
      loadedTemplatesOnce.current = true;
      setLoadingTemplates(true);
      try {
        const result: IPaginationTemplatesDTO =
          await getTemplatePagesByCompanyId({
            clientId: companyId,
            templateOptionsId,
            page: 1,
            limit: 10,
            filter: '',
          });

        if (mount) {
          const templatesAux = new Map<number, ITemplatePage>(
            Array.from(templates.values())
              .filter((item) => item.selected === true)
              .map((item) => [item.id, item])
          );
          result.data.forEach((item: ITemplatePage) => {
            if (!templatesAux.has(item.id)) {
              templatesAux.set(item.id, item);
            }
          });

          setPaginationMeta(result.meta);

          // get all templateAux selected true
          const templateAuxSelectedTrue = Array.from(templates.values()).filter(
            (item) => item.selected === true
          );

          templatesAux.forEach((item) => {
            const isSelected = checkSelectedTemplate(item.id);
            const template = templatesAux.get(item.id);
            if (template) {
              templatesAux.set(item.id, {
                ...template,
                selected: isSelected,
                templateOptionsId,
              });
            }
          });
          // union both templates
          const unionTemplates = templateAuxSelectedTrue
            .concat(Array.from(templatesAux.values()))
            .sort((a, b) => {
              // Verifica se ambos os objetos têm a propriedade `order`
              if (a.order !== undefined && b.order !== undefined) {
                return a.order - b.order;
              }
              // Se apenas um dos objetos tiver a propriedade `order`, esse objeto deve vir depois
              if (a.order !== undefined) {
                return -1;
              }
              if (b.order !== undefined) {
                return 1;
              }
              // Se nenhum dos objetos tiver a propriedade `order`, mantenha a ordem original
              return 0;
            });

          setTemplates(new Map(unionTemplates.map((item) => [item.id, item])));
        }
      } catch (err) {
        loadedTemplatesOnce.current = false;
      } finally {
        setLoadingTemplates(false);
        loadedTemplatesOnce.current = false;
      }
    };

    if (open && steps[currentStep].id !== 'CREATE') {
      if (
        (companyId && !loadedTemplatesOnce.current) ||
        (Array.from(templates.values()).length === 0 && !templateGroupUpdate) ||
        templateOptionsId
      ) {
        void loadTemplatesOnce();
      }
    }

    return () => {
      mount = false;
    };
  }, [
    templateOptionsId,
    open,
    companyId,
    loadedTemplatesOnce,
    templateGroupUpdate,
    currentStep,
  ]);

  const handleUpdatePage = useCallback(async () => {
    setLoadingCreatingCourse(true);
    const pageTemplateIds: Record<number, number> = {};

    Object.entries(selectedTemplates).forEach(([_, value]) => {
      pageTemplateIds[value.order] = value.id;
    });

    await onUpdate?.({
      title: groupTemplateTitle,
      pageTemplates: pageTemplateIds,
      templateOptions,
      id: templateGroupUpdate?.id ?? 0,
    });
    setLoadingCreatingCourse(false);
    handleDeclineAddPage();
  }, [
    selectedTemplates,
    onUpdate,
    groupTemplateTitle,
    templateOptions,
    templateGroupUpdate?.id,
    handleDeclineAddPage,
  ]);

  const handleAddPage = useCallback(async () => {
    setLoadingCreatingCourse(true);
    const pageTemplateIds: Record<number, number> = {};

    Object.entries(selectedTemplates).forEach(([_, value]) => {
      pageTemplateIds[value.order] = value.id;
    });

    await onInsert?.({
      title: groupTemplateTitle,
      pageTemplates: pageTemplateIds,
      client: companyId,
      templateOptions,
    });
    handleDeclineAddPage();
  }, [
    selectedTemplates,
    onInsert,
    groupTemplateTitle,
    companyId,
    templateOptions,
    handleDeclineAddPage,
  ]);

  const handleVerifyAction = useCallback(() => {
    if (!templateGroupUpdate) {
      void handleAddPage();
    } else {
      void handleUpdatePage();
    }
  }, [templateGroupUpdate, handleAddPage, handleUpdatePage]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const { addDragDropListener, removeDragDropListener } = useDragDrop();

  useEffect(() => {
    const handleValueChange = ({ droppableId, from, to }: IDroppableProps) => {
      if (droppableId !== DroppableId) return;
      setSelectedTemplates((prevSelectedTemplates) => {
        const sortedTemplates = Object.values(prevSelectedTemplates).sort(
          (a, b) => a.order - b.order
        );

        const [movedItem] = sortedTemplates.splice(from, 1);
        sortedTemplates.splice(to, 0, movedItem);

        const updatedTemplates: ITemplatePageWithOrder[] =
          sortedTemplates.reduce<ITemplatePageWithOrder[]>(
            (acc, template, index) => {
              acc[template.id] = { ...template, order: index + 1 };
              return acc;
            },
            []
          );

        return updatedTemplates;
      });
    };

    addDragDropListener(DroppableId, handleValueChange);

    return () => {
      removeDragDropListener(DroppableId);
    };
  }, [addDragDropListener, removeDragDropListener, DroppableId]);

  return (
    <FormPaginationModal
      {...props}
      open={open}
      acceptText={
        steps[currentStep].id === 'CREATE'
          ? 'Next'
          : templateGroupUpdate
          ? 'Update Course Template'
          : 'Create Course Template'
      }
      dataCy="add-course-page-form-modal"
      declineText={steps[currentStep].id === 'CREATE' ? 'Cancel' : 'Back'}
      mainText={
        templateGroupUpdate ? 'Update Course Template' : 'New Course Template'
      }
      disabled={disabledButtonNext()}
      loading={loadingCreatingCourse || loading}
      onAccept={() => {
        if (steps[currentStep].id === 'CREATE') {
          handleNextStep();
        } else {
          handleVerifyAction();
        }
      }}
      onDecline={
        steps[currentStep].id === 'CREATE'
          ? handleDeclineAddPage
          : handlePreviousStep
      }
      onOpenChange={handleDeclineAddPage}
      refContent={refFormModalContent}
      fullWidth={steps[currentStep].id === 'ADD'}
      biggerModal={steps[currentStep].id === 'ADD'}
      vhHeight={steps[currentStep].id === 'ADD' ? '90vh' : ''}
    >
      {steps[currentStep].id === 'CREATE' && (
        <ModalInfoGroupTemplate
          templateTitle={groupTemplateTitle}
          setTemplateTitle={setGroupTemplateTitle}
          templateOptions={templateOptions}
          setTemplateOptions={setTemplateOptions}
          isUserAdmin={isUserAdmin}
          isUserManager={isUserManager}
          templateOptionsStaff={groupOptionsStaff}
          templatesOptionsFiltered={groupOptionsFiltered}
          templateOptionsManager={groupOptionsManager}
          isModalUpdate={false}
          onOpenChange={(isOpen) => {
            handleOpenChange(isOpen, 'options');
          }}
        />
      )}
      {steps[currentStep].id === 'ADD' && (
        <>
          <TitleText data-cy="text-selectTemplates">
            Select the option of template you would like to use.
            {!noItemsSelected &&
              ` (${Object.keys(selectedTemplates).length} selected)`}
          </TitleText>
          <SelectTemplateTypeContainer data-cy="template-content">
            <SelectData
              data={
                isUserAdmin
                  ? templatesOptionsFiltered
                  : isUserManager
                  ? templateOptionsManager
                  : templateOptionsStaff
              }
              placeholder="Choose a template type"
              onValueChange={(id) => {
                setTemplateOptionsId(+id);
              }}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'options');
              }}
              zIndex={10}
              value={String(templateOptionsId)}
            />
          </SelectTemplateTypeContainer>

          <MainContainer>
            <FilterPagination
              search={search ?? ''}
              placeholder="Search template"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <Content>
              {loadingTemplates &&
              Array.from(templates.values()).length === 0 ? (
                <LoadingContainer data-cy="loading-templates-container">
                  <Loading dataCy="loading-templates" />
                </LoadingContainer>
              ) : (
                <ContentPaginationCustom dataCy="content-templates">
                  {loadingTemplates &&
                  Array.from(templates.values()).length === 0 ? (
                    <LoadingContainer data-cy="loading-templates-container">
                      <Loading dataCy="loading-templates" />
                    </LoadingContainer>
                  ) : Array.from(templates.values()).length > 0 ? (
                    <InfiniteScroll
                      height={200}
                      style={{
                        overflow: 'auto',
                        maxHeight: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                      }}
                      dataLength={Array.from(templates.values()).length} // This is important field to render the next data
                      next={searchTemplates}
                      hasMore={
                        Array.from(templates.values()).length <
                          (paginationMeta?.total ?? 0) &&
                        paginationMeta?.page !== paginationMeta?.total_pages
                      }
                      loader={
                        <LoadingContainer data-cy="loading-templates-container">
                          <Loading />
                        </LoadingContainer>
                      }
                      endMessage={
                        <CenteredText
                          message="You have seen it all"
                          dataCy="end-limit-pagination"
                        />
                      }
                    >
                      <CustomCheckBoxGroup
                        {...props}
                        type="multiple"
                        data-cy="select-template-container"
                      >
                        {Array.from(templates.values()).map((data) => (
                          <TemplateCardCustom
                            data={data}
                            key={data.id}
                            disabled={
                              data.selected === true || loadingTemplates
                            }
                            data-state={
                              data.selected === false ||
                              data.selected === undefined
                                ? 'off'
                                : 'on'
                            }
                            onClick={() => {
                              checkTemplate(data as ITemplatePageWithOrder);
                            }}
                          />
                        ))}
                      </CustomCheckBoxGroup>
                    </InfiniteScroll>
                  ) : (
                    <CenteredText
                      message="No templates found"
                      dataCy="text-noTemplates-found"
                    />
                  )}
                </ContentPaginationCustom>
              )}
              {Object.keys(sortedSelectedTemplates).length > 0 ? (
                <CustomCheckBoxGroup
                  {...props}
                  type="multiple"
                  data-cy="select-template-container"
                >
                  <ContentPaginationCustom dataCy="content-templates">
                    <SelectContainer data-cy="container-templatesSelect">
                      <Droppable
                        data-cy="droppable-cards-container"
                        isDropDisabled={loadingTemplates}
                        droppableId={DroppableId}
                      >
                        {(provided) => (
                          <CardContainer
                            data-cy="card-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {Object.values(sortedSelectedTemplates).map(
                              (data, index) => (
                                <Card
                                  data-cy={data.id}
                                  draggableId={data.id}
                                  index={index}
                                  key={data.id}
                                  draggable
                                  refViewPort={refFormModalContent}
                                >
                                  <TemplateCardCustom
                                    disabled={loadingTemplates}
                                    data={data}
                                    key={data.id}
                                    data-state="on"
                                    onClick={() => {
                                      removeTemplate(data);
                                    }}
                                  />
                                </Card>
                              )
                            )}
                            {provided.placeholder}
                          </CardContainer>
                        )}
                      </Droppable>
                    </SelectContainer>
                  </ContentPaginationCustom>
                </CustomCheckBoxGroup>
              ) : (
                <CenteredText
                  message="No templates selected"
                  dataCy="text-noTemplates-selected"
                />
              )}
            </Content>
          </MainContainer>
        </>
      )}
    </FormPaginationModal>
  );
};
